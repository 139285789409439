import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';
import { AnonymousUserType } from '../../AuthenticatedView';

interface GetOrCreateUserVariables {
  referralCode: string | undefined;
}

interface GetOrCreateUserResult {
  uid: string;
  isOnboarded: boolean;
  anonymousUserInfo: {
    anonymousUserType: AnonymousUserType;
    linkedAt: string;
  };
  workspaces: [
    {
      joinableTeams: {
        teamUid: string;
        numMembers: number;
        name: string;
        teamAcceptingInvites: boolean;
      }[];
    }
  ];
  deletedAnonymousUser: boolean;
}

export default (
  variables: GetOrCreateUserVariables,
  anonymousUserToken: string | null
) => {
  const context: { headers: any } = {
    headers: {},
  };
  if (anonymousUserToken) {
    context.headers['X-Warp-Anonymous-Token'] = anonymousUserToken;
  }
  const query = gql`
    mutation GetOrCreateUser(
      $input: GetOrCreateUserInput!
      $requestContext: RequestContext!
    ) {
      getOrCreateUser(requestContext: $requestContext, input: $input) {
        ... on GetOrCreateUserOutput {
          uid
          isOnboarded
          anonymousUserInfo {
            anonymousUserType
            linkedAt
          }
          workspaces {
            joinableTeams {
              teamUid
              numMembers
              name
              teamAcceptingInvites
            }
          }
          deletedAnonymousUser
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  const vars = {
    input: variables,
    requestContext: getRequestContext(),
  };

  const parseData = (data: any): GetOrCreateUserResult => {
    return data?.getOrCreateUser;
  };

  return {
    query,
    variables: vars,
    parseData,
    context,
  };
};
