import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export default () => {
  const query = gql`
    mutation CreateAnonymousUser(
      $input: CreateAnonymousUserInput!
      $requestContext: RequestContext!
    ) {
      createAnonymousUser(input: $input, requestContext: $requestContext) {
        ... on CreateAnonymousUserOutput {
          idToken
        }
      }
    }
  `;

  const variables = {
    input: {
      anonymousUserType: 'WEB_CLIENT_ANONYMOUS_USER',
      expirationType: 'NO_EXPIRATION',
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.createAnonymousUser?.idToken;
  };

  return { query, variables, parseData };
};
