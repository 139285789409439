import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface StripeBillingPortalVariables {
  teamUid: string | undefined;
}

export default (variables: StripeBillingPortalVariables) => {
  const query = gql`
    mutation StripeBillingPortal(
      $input: StripeBillingPortalInput!
      $requestContext: RequestContext!
    ) {
      stripeBillingPortal(requestContext: $requestContext, input: $input) {
        ... on StripeBillingPortalOutput {
          url
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  const vars = {
    input: {
      teamUid: variables.teamUid,
    },
    requestContext: getRequestContext(),
  };

  return {
    query,
    variables: vars,
    parseData: (data: any): string | undefined => {
      return data?.stripeBillingPortal?.url;
    },
  };
};
