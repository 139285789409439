import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface TransferTeamOwnershipInput {
  newOwnerEmail: string;
}

export default (variables: TransferTeamOwnershipInput) => {
  const query = gql`
    mutation TransferTeamOwnership(
      $requestContext: RequestContext!
      $input: TransferTeamOwnershipInput!
    ) {
      transferTeamOwnership(requestContext: $requestContext, input: $input) {
        ... on TransferTeamOwnershipOutput {
          success
        }
      }
    }
  `;

  const vars = {
    requestContext: getRequestContext(),
    input: variables,
  };

  const parseData = (data: any) => {
    return data?.transferTeamOwnership?.success;
  };

  return { query, variables: vars, parseData };
};
