import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';
import {
  mapCustomerTypeToEnum,
  TeamInfoForUpgradeView,
} from '../queries/GetUser';
import { AllowedCustomerTypes } from '../../upgrade-view/UpgradeEnums';

interface CreateTeamVariables {
  name: string;
  entrypoint: string;
}

export default () => {
  const query = gql`
    mutation CreateTeam(
      $input: CreateTeamInput!
      $requestContext: RequestContext!
    ) {
      createTeam(input: $input, requestContext: $requestContext) {
        ... on CreateTeamOutput {
          workspace {
            teams {
              uid
              adminUid
              members {
                email
                uid
              }
            }
            billingMetadata {
              customerType
            }
          }
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  const parseData = (data: any): TeamInfoForUpgradeView[] => {
    if (!data?.createTeam?.workspace?.teams) {
      return [];
    }

    const { workspace } = data.createTeam;
    const { billingMetadata } = workspace;
    const adminEmail = workspace.teams[0].members.find(
      (member: any) => member.uid === workspace.teams[0].adminUid
    )?.email;
    const customerType =
      mapCustomerTypeToEnum(billingMetadata?.customerType) ??
      AllowedCustomerTypes.FREE;

    return workspace.teams.map((team: any) => {
      const teamInfo: TeamInfoForUpgradeView = {
        uid: team.uid,
        adminUid: team.adminUid,
        customerType,
        members: team.members,
        adminEmail,
      };

      return teamInfo;
    });
  };

  return {
    query,
    createVariables: (variables: CreateTeamVariables) => ({
      input: variables,
      requestContext: getRequestContext(),
    }),
    parseData,
  };
};
