interface FeatureFlags {
  REACT_APP_ENABLE_MULTI_WORKSPACE: boolean;
}

const FEATURE_FLAGS: FeatureFlags = {
  REACT_APP_ENABLE_MULTI_WORKSPACE:
    process.env.REACT_APP_ENABLE_MULTI_WORKSPACE === 'true',
};

function getDefaultScheme(): string {
  return process.env.REACT_APP_DEFAULT_SCHEME!.trim();
}

function isProd(): Boolean {
  return process.env.REACT_APP_DEFAULT_SCHEME === 'warp';
}

function isStaging(): Boolean {
  return process.env.REACT_APP_DEFAULT_SCHEME === 'warpdev';
}

export { getDefaultScheme, isProd, isStaging, FEATURE_FLAGS };
