import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export interface Team {
  uid: string;
  name: string;
}

export interface Workspace {
  uid: string;
  name: string;
  teams: Team[];
}

const PLACEHOLDER_WORKSPACE_UID = 'NOT_A_REAL_WORKSPACE_UID';

export default () => {
  const query = gql`
    query GetUserWorkspaces($requestContext: RequestContext!) {
      user(requestContext: $requestContext) {
        ... on UserOutput {
          user {
            workspaces {
              name
              uid
              teams {
                name
                uid
              }
            }
          }
        }
      }
    }
  `;

  const variables = {
    requestContext: getRequestContext(),
  };

  const parseData = (data: any): Workspace[] => {
    // exclude placeholder workspaces
    return data?.user?.user?.workspaces
      ?.filter((workspace: any) => workspace.uid !== PLACEHOLDER_WORKSPACE_UID)
      .map((workspace: any) => {
        return {
          uid: workspace.uid,
          name: workspace.name,
          teams: workspace.teams.map((team: any) => {
            return {
              uid: team.uid,
              name: team.name,
            };
          }),
        };
      });
  };

  return { query, variables, parseData };
};
