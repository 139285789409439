import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface GetReferrerVariables {
  referralCode: string | undefined;
}

export default (variables: GetReferrerVariables) => {
  const query = gql`
    query referrer($input: ReferrerInput!, $requestContext: RequestContext!) {
      referrer(input: $input, requestContext: $requestContext) {
        ... on ReferrerOutput {
          referrer
        }
      }
    }
  `;

  const vars = {
    input: {
      referralCode: variables.referralCode,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.referrer?.referrer;
  };

  return { query, variables: vars, parseData };
};
