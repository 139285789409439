import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export default () => {
  const query = gql`
    mutation DeleteUser($requestContext: RequestContext!) {
      deleteUser(requestContext: $requestContext) {
        ... on DeleteUserOutput {
          success
        }
      }
    }
  `;

  const variables = {
    requestContext: getRequestContext(),
  };

  return { query, variables };
};
