import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export interface JoinTeamWithAuthCodeVariables {
  inviteCode: string;
  authCode: string;
}

export default (variables: JoinTeamWithAuthCodeVariables) => {
  const query = gql`
    mutation JoinTeamWithAuthCode(
      $input: JoinTeamWithAuthCodeInput!
      $requestContext: RequestContext!
    ) {
      joinTeamWithAuthCode(input: $input, requestContext: $requestContext) {
        ... on JoinTeamWithAuthCodeOutput {
          success
        }
      }
    }
  `;

  const vars = {
    input: {
      teamInviteCode: variables.inviteCode,
      authCode: variables.authCode,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.joinTeamWithAuthCode?.success;
  };

  return { query, variables: vars, parseData };
};
