import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface SendRequestObjectAccessEmailVariables {
  uid: string;
}

export default (variables: SendRequestObjectAccessEmailVariables) => {
  const query = gql`
    mutation SendRequestObjectAccessEmail(
      $input: SendRequestObjectAccessEmailInput!
      $requestContext: RequestContext!
    ) {
      sendRequestObjectAccessEmail(
        input: $input
        requestContext: $requestContext
      ) {
        ... on SendRequestObjectAccessEmailOutput {
          success
        }
      }
    }
  `;

  const vars = {
    input: {
      uid: variables.uid,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.sendRequestObjectAccessEmail?.success;
  };

  return { query, variables: vars, parseData };
};
