import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export default () => {
  const query = gql`
    query GetUserSettings($requestContext: RequestContext!) {
      user(requestContext: $requestContext) {
        ... on UserOutput {
          user {
            settings {
              isTelemetryEnabled
            }
          }
        }
      }
    }
  `;

  const vars = {
    requestContext: getRequestContext(),
  };

  return {
    query,
    variables: vars,
    parseData: (data: any) => !!data?.user?.user?.settings?.isTelemetryEnabled,
  };
};
