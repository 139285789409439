import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface JoinTeamWithTeamDiscoveryVariables {
  teamUid: string;
  entrypoint: string;
}

export default (variables: JoinTeamWithTeamDiscoveryVariables) => {
  const query = gql`
    mutation JoinTeamWithTeamDiscovery(
      $input: JoinTeamWithTeamDiscoveryInput!
      $requestContext: RequestContext!
    ) {
      joinTeamWithTeamDiscovery(
        input: $input
        requestContext: $requestContext
      ) {
        ... on JoinTeamWithTeamDiscoveryOutput {
          success
        }
      }
    }
  `;

  const vars = {
    input: {
      teamUid: variables.teamUid,
      entrypoint: variables.entrypoint,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.joinTeamWithTeamDiscovery?.success;
  };

  return { query, variables: vars, parseData };
};
