import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { isProd } from './utils/env';
import {
  ApolloWrapperProps,
  AuthenticatedApolloWrapper,
  NoAuthApolloWrapper,
} from './ApolloWrapper';
import DownloadPage from './DownloadPage';
import CanaryPage from './CanaryPage';
import LoggedInView from './authed/LoggedInView';
import { JoinTeamView } from './JoinTeamView';
import { JoinTeamWithInviteEmailView } from './JoinTeamWithInviteEmailView';
import { UserDataView } from './UserDataView';
import DriveView from './DriveView';
import DevSession from './DevSession';
import LoggedInWithDownloadView from './authed/LoggedInWithDownloadView';
import './index.css';
import './dark-theme.css';
import './xterm_colors.css';
import './dropdown.css';
import { warpCanaryPageEnabled } from './utils/features';
import EmbedView from './block-sharing/EmbedView';
import ShareView from './block-sharing/ShareView';
import useSearchQuery from './utils/useSearchQuery';
import AuthView from './auth/AuthView';
import ReferredSignupView from './ReferredSignupView';
import LinkSsoView from './auth/LinkSsoView';
import UpgradeView from './upgrade-view/UpgradeView';
import UpgradeConfirmationView from './upgrade-view/UpgradeConfirmationView';
import SessionShareView from './SessionShareView';
import SettingsView from './SettingsView';
import UpgradeRequestSentView from './upgrade-view/UpgradeRequestSentView';
import LinkAnonymousUserView from './auth/LinkAnonymousUser';
import Warp404 from './404';
import WarpHomeView from './WarpHomeView';
import AuthOptionView from './auth/AuthOptionView';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
      });
    }
  }, [location]);

  const searchQuery = useSearchQuery();
  const shouldAutoDownload = searchQuery.has('auto_download')
    ? searchQuery.get('auto_download') === 'true'
    : true;

  return (
    <Routes>
      <Route
        path="/login/*"
        element={
          <AuthenticatedApolloWrapper allowAnonymous>
            {({ user, logout }: ApolloWrapperProps) => (
              <AuthView
                headerContent={<>Sign in to Warp</>}
                showLoadingState={false}
                buttonTrackingObject={{
                  segmentMessage: 'Click Sign In Button on Login View',
                  googleAnalyticsMessage: 'Login on Login View',
                  googleAnalyticsCategory: 'Login',
                }}
                user={user}
                logout={logout}
              />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/signup/*"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, logout }: ApolloWrapperProps) => (
              <AuthView
                headerContent={<>Sign up for Warp</>}
                showLoadingState={false}
                buttonTrackingObject={{
                  segmentMessage: 'Click Sign Up Button on Sign Up View',
                  googleAnalyticsMessage: 'Sign Up on Sign Up View',
                  googleAnalyticsCategory: 'Sign Up',
                }}
                user={user}
                logout={logout}
              />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/link_sso"
        element={
          <AuthenticatedApolloWrapper allowAnonymous>
            {({ user, deletedAnonymousUser, logout }: ApolloWrapperProps) => (
              <LinkSsoView
                user={user}
                deletedAnonymousUser={deletedAnonymousUser}
                logout={logout}
              />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/link_anonymous_user/:customToken?"
        element={
          <AuthenticatedApolloWrapper allowAnonymous>
            {({ user, setAnonymousUserLinked, logout }: ApolloWrapperProps) => (
              <LinkAnonymousUserView
                user={user}
                setAnonymousUserLinked={setAnonymousUserLinked}
                logout={logout}
              />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/login_options/:customToken?"
        element={
          <AuthenticatedApolloWrapper allowAnonymous>
            {({ user }: ApolloWrapperProps) => <AuthOptionView user={user} />}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/referral/:referral_code"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, setReferralCode, logout }: ApolloWrapperProps) => (
              <ReferredSignupView
                user={user}
                setReferralCode={setReferralCode}
                logout={logout}
              />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/logged_in/download/*"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, logout }: ApolloWrapperProps) => (
              <LoggedInWithDownloadView user={user} logout={logout} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/logged_in/*"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, deletedAnonymousUser, logout }: ApolloWrapperProps) => (
              <LoggedInView
                user={user}
                deletedAnonymousUser={deletedAnonymousUser}
                logout={logout}
              />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/team_invite/:emailAuthCode"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, logout }: ApolloWrapperProps) => (
              <JoinTeamWithInviteEmailView user={user} logout={logout} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/team/:inviteCode"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, logout }: ApolloWrapperProps) => (
              <JoinTeamView user={user} logout={logout} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/data_management"
        element={
          <AuthenticatedApolloWrapper
            allowAnonymous
            loginAnonymousUserWithTokenQueryParam
          >
            {({
              user,
              anonymousUserProvidedLoginToken,
            }: ApolloWrapperProps) => (
              <UserDataView
                user={user}
                anonymousUserProvidedLoginToken={
                  anonymousUserProvidedLoginToken
                }
              />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/drive/:object_type/:object_slug"
        element={
          <AuthenticatedApolloWrapper
            allowAnonymous
            createAnonymousUserIfUnauthenticated
          >
            {({ user, logout }: ApolloWrapperProps) => (
              <DriveView user={user} logout={logout} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/upgrade_request_sent"
        element={
          <AuthenticatedApolloWrapper>
            {() => <UpgradeRequestSentView />}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/upgrade/:teamUid"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, logout }: ApolloWrapperProps) => (
              <UpgradeView user={user} logout={logout} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/upgrade/user/:firebaseUid"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, logout }: ApolloWrapperProps) => (
              <UpgradeView user={user} logout={logout} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/upgrade"
        element={
          <AuthenticatedApolloWrapper>
            {({ user, logout }: ApolloWrapperProps) => (
              <UpgradeView user={user} logout={logout} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/confirmation"
        element={
          <AuthenticatedApolloWrapper>
            {({ user }: ApolloWrapperProps) => (
              <UpgradeConfirmationView user={user} />
            )}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/session/:id"
        element={
          <AuthenticatedApolloWrapper
            allowAnonymous
            createAnonymousUserIfUnauthenticated
          >
            {({ user }: ApolloWrapperProps) => <SessionShareView user={user} />}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/settings/:sub_section"
        element={
          <AuthenticatedApolloWrapper>
            {({ user }: ApolloWrapperProps) => <SettingsView user={user} />}
          </AuthenticatedApolloWrapper>
        }
      />
      {!isProd() && (
        <Route
          path="/dev_session"
          element={
            <AuthenticatedApolloWrapper>
              {({ user }: ApolloWrapperProps) => <DevSession user={user} />}
            </AuthenticatedApolloWrapper>
          }
        />
      )}
      <Route
        path="/download/r/:invite_code"
        element={
          <NoAuthApolloWrapper>
            <DownloadPage canAutoStartDownload={false} />
          </NoAuthApolloWrapper>
        }
      />
      <Route
        path="/get_warp"
        element={
          <NoAuthApolloWrapper>
            <DownloadPage canAutoStartDownload={shouldAutoDownload} />
          </NoAuthApolloWrapper>
        }
      />
      {warpCanaryPageEnabled() && (
        <Route
          path="/canary"
          element={
            <NoAuthApolloWrapper>
              <CanaryPage />
            </NoAuthApolloWrapper>
          }
        />
      )}
      <Route
        path="/block/embed/:uid"
        element={
          <NoAuthApolloWrapper>
            <EmbedView />
          </NoAuthApolloWrapper>
        }
      />
      <Route
        path="/block/:uid"
        element={
          <NoAuthApolloWrapper>
            <ShareView />
          </NoAuthApolloWrapper>
        }
      />
      <Route
        path="/app"
        element={
          <AuthenticatedApolloWrapper>
            {({ user }: ApolloWrapperProps) => <WarpHomeView user={user} />}
          </AuthenticatedApolloWrapper>
        }
      />
      <Route
        path="/:uid"
        element={
          <NoAuthApolloWrapper>
            <ShareView />
          </NoAuthApolloWrapper>
        }
      />
      {/*
      Note that, since /:uid is a valid route, ShareView above is responsible for showing a 404
      for URLs that aren't block sharing related, like app.warp.dev/foobar.
      This catch-all here is still necessary, however, for paths like app.warp.dev/foo/bar.
      */}
      <Route path="*" element={<Warp404 />} />
      <Route path="/" element={<Navigate to="/app" />} />
    </Routes>
  );
};

export default App;
