import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import ModalContainerButtonSpacer from '../modal-container/ModalContainerButtonSpacer';

interface NotInTeamModalProps {
  logout: () => Promise<void>;
}

const InvalidTeamLinkModal = ({ logout }: NotInTeamModalProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Alert}>
      <ModalContainerHeader>Invalid upgrade link</ModalContainerHeader>
      <ModalContainerBody>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div>
            You do not have permission to upgrade this account because you are
            not currently logged in as a member of this team.
          </div>
          <div>
            Switch accounts or confirm team membership with your Warp admin.
          </div>
        </div>

        <ModalContainerButtonSpacer>
          <ModalContainerButton
            accent={ModalContainerButtonAccent.Destructive}
            treatment={ModalContainerButtonTreatment.FullWidth}
            content={<>Log out</>}
            onClickFunction={logout}
          />
        </ModalContainerButtonSpacer>
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default InvalidTeamLinkModal;
