import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  OAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithPopup,
} from 'firebase/auth';
import firebase from '../utils/firebase';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import './email-sent-view.css';
import { User } from '../AuthenticatedView';
import AuthView from './AuthView';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';

import { computeAppLaunchUrl, computeAuthResult } from '../authed/LoggedInView';
import useSearchQuery from '../utils/useSearchQuery';
import GetOrganizationQuery from '../graphql/queries/GetOrganization';

interface LinkSsoViewProps {
  user: User | undefined;
  deletedAnonymousUser: boolean;
  logout: () => Promise<void>;
}

const DEFAULT_PROVIDERS = ['password', 'google.com', 'github.com'];

const LinkSsoView = ({
  user,
  deletedAnonymousUser,
  logout,
}: LinkSsoViewProps) => {
  const query = useSearchQuery();
  const auth = getAuth(firebase);
  const { currentUser } = auth;
  const [justAuthedWithSso, setJustAuthedWithSso] = useState<boolean>(false);
  const [
    isFetchingSignInMethods,
    setIsFetchingSignInMethods,
  ] = useState<boolean>(false);
  const [previousSignInMethods, setPreviousSignInMethods] = useState<
    string[] | undefined
  >(undefined);
  const emailFromQueryParams = query.get('email');

  const {
    query: getOrganizationQuery,
    variables: getOrganizationVariables,
    parseData: getOrganizationParseData,
  } = GetOrganizationQuery({ email: currentUser?.email || '' });
  const { loading: organizationQueryLoading, data } = useQuery(
    getOrganizationQuery,
    {
      variables: getOrganizationVariables,
    }
  );
  const parsedData = getOrganizationParseData(data);
  const needsSsoLink = parsedData?.organizationId && parsedData?.ssoEnabled;
  const hasSsoLink = currentUser
    ? currentUser.providerData.some(
        (provider) => provider.providerId === 'oidc.workos'
      )
    : false;

  const fetchValidMethods = async () => {
    // We only want to run this code once;
    // we pretty quickly run into Firebase API rate limits otherwise
    if (!isFetchingSignInMethods) {
      setIsFetchingSignInMethods(true);

      // If we don't have an email from the query params, then we can't prepopulate the correct
      // providers. We'll just show the non-SSO ones (if the user's on this linking page, they
      // shouldn't be able to use SSO to sign in yet, so omit it).
      if (!emailFromQueryParams) {
        setPreviousSignInMethods(DEFAULT_PROVIDERS);
        return;
      }

      // If we do have an email from the URL query params, then we can show only the providers that are
      // valid for that email. Though, there's no guarantee the user will sign in with that email.
      try {
        const validMethods = await fetchSignInMethodsForEmail(
          auth,
          emailFromQueryParams
        );
        setPreviousSignInMethods(validMethods);
      } catch (err: any) {
        // If we do get rate limited, we can't be any smarter than showing all possible providers.
        setPreviousSignInMethods(DEFAULT_PROVIDERS);
      }
    }
  };
  fetchValidMethods();

  const loadingState = (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>Loading...</ModalContainerHeader>
    </ModalContainer>
  );

  // If there's no current user, they need to sign in.
  if (!currentUser || !user) {
    // If we haven't finished fetching the previous sign in methods, then show the loading state
    if (!previousSignInMethods) {
      return loadingState;
    }

    return (
      <AuthView
        headerContent={<>To link SSO, first sign in again</>}
        enabledProviders={previousSignInMethods}
        showLoadingState={false}
        buttonTrackingObject={{
          segmentMessage: 'Click Sign In Button on Login View',
          googleAnalyticsMessage: 'Login on Login View',
          googleAnalyticsCategory: 'Login',
        }}
        user={user}
        logout={logout}
      />
    );
  }

  // If the user is signed in, but we're still fetching their organization state, then
  // show the loading state
  if (organizationQueryLoading) {
    return loadingState;
  }

  if (!needsSsoLink) {
    return (
      <ModalContainer iconType={ModalContainerIconType.Warning}>
        <ModalContainerHeader>No SSO provider to link</ModalContainerHeader>
        <ModalContainerBody>
          <>
            <div>
              It appears you don&apos;t have an SSO provider associated with
              your email domain. No action is required from you at this time.
            </div>
          </>
        </ModalContainerBody>
      </ModalContainer>
    );
  }

  const authResult = computeAuthResult(user, deletedAnonymousUser);
  const appLaunchUrl = computeAppLaunchUrl(query, authResult);

  if (hasSsoLink || justAuthedWithSso) {
    return (
      <ModalContainer iconType={ModalContainerIconType.Check}>
        <ModalContainerHeader>SSO linked!</ModalContainerHeader>
        <ModalContainerBody>
          <>
            <div>
              You&apos;re all set. From now on, if you ever need to sign into
              Warp, please do so with SSO.
            </div>
            <ModalContainerButton
              content={<>Take me to Warp</>}
              treatment={ModalContainerButtonTreatment.FullWidth}
              accent={ModalContainerButtonAccent.Primary}
              href={appLaunchUrl}
            />
          </>
        </ModalContainerBody>
      </ModalContainer>
    );
  }

  const signInWithSso = async () => {
    const provider = new OAuthProvider('oidc.workos');
    provider.addScope('user:email');
    provider.setCustomParameters({
      organization: parsedData?.organizationId,
    });
    await linkWithPopup(auth.currentUser!, provider);
    setJustAuthedWithSso(true);
  };

  return (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>
        Your organization requires SSO authentication
      </ModalContainerHeader>
      <ModalContainerBody>
        <>
          <div>
            Sign in with your SSO provider to link it to your Warp account.
          </div>
          <ModalContainerButton
            content={<>Sign in with SSO</>}
            treatment={ModalContainerButtonTreatment.FullWidth}
            accent={ModalContainerButtonAccent.Primary}
            onClickFunction={signInWithSso}
          />
        </>
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default LinkSsoView;
