import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import { User } from '../AuthenticatedView';
import firebase from '../utils/firebase';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButtonSpacer from '../modal-container/ModalContainerButtonSpacer';
import { ON_LOGIN_REDIRECT_QUERY_KEY } from '../utils/redirectAfterLogin';

interface AuthOptionViewProps {
  user: User | undefined;
}

const AuthOptionView = ({ user }: AuthOptionViewProps) => {
  const auth = getAuth(firebase);
  const { currentUser } = auth;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const redirectPath = queryParams.get(ON_LOGIN_REDIRECT_QUERY_KEY);
  const { customToken } = useParams<{
    customToken: string;
  }>();

  // Run a one-time function to potentially sign the anonymous user into Firebase.
  // Note that this is distinct from getting the user from Warp.
  useEffect(() => {
    const checkAnonymousUserSignedIn = async () => {
      await signInWithCustomToken(auth, customToken || '');
    };

    if (!currentUser) {
      checkAnonymousUserSignedIn();
    }
  }, [auth, customToken, currentUser]);

  useEffect(() => {
    if (user) {
      if (!user.isAnonymous) {
        if (redirectPath) {
          navigate(redirectPath, {
            replace: true,
          });
        } else {
          navigate('/logged_in/remote', {
            replace: true,
          });
        }
      }

      if (customToken) {
        if (redirectPath) {
          navigate(
            `/login_options?${ON_LOGIN_REDIRECT_QUERY_KEY}=${redirectPath}`,
            {
              replace: true,
            }
          );
        } else {
          navigate('/login_options', {
            replace: true,
          });
        }
      }
    }
  }, [customToken, navigate, redirectPath, user]);

  return (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>Welcome to Warp</ModalContainerHeader>
      <ModalContainerBody>
        Use Warp with an account to unlock increased AI limits and collaboration
        features.
        <ModalContainerButtonSpacer>
          <ModalContainerButton
            content={<>Sign up</>}
            treatment={ModalContainerButtonTreatment.FullWidth}
            accent={ModalContainerButtonAccent.Primary}
            onClickFunction={() => {
              if (user?.isAnonymous) {
                if (redirectPath) {
                  navigate(
                    `/link_anonymous_user?${ON_LOGIN_REDIRECT_QUERY_KEY}=${redirectPath}`
                  );
                } else {
                  navigate('/link_anonymous_user');
                }
              } else {
                navigate('/signup');
              }
            }}
          />
          <ModalContainerButton
            content={<>Log in to an existing account</>}
            treatment={ModalContainerButtonTreatment.FullWidth}
            accent={ModalContainerButtonAccent.Secondary}
            onClickFunction={() => {
              navigate('/login');
            }}
          />
        </ModalContainerButtonSpacer>
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default AuthOptionView;
