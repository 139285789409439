import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';
import { AllowedCustomerTypes } from '../../upgrade-view/UpgradeEnums';

export interface TeamInfoForUpgradeView {
  uid: string;
  adminUid: string;
  customerType: string;
  members: {
    uid: string;
    email: string;
  }[];
  adminEmail: string;
}

export function mapCustomerTypeToEnum(
  customerType: string
): AllowedCustomerTypes | null {
  switch (customerType) {
    case 'FREE':
      return AllowedCustomerTypes.FREE;
    case 'SELF_SERVE':
      return AllowedCustomerTypes.SELF_SERVE;
    case 'PROSUMER':
      return AllowedCustomerTypes.PROSUMER;
    case 'LEGACY':
      return AllowedCustomerTypes.LEGACY;
    case 'ENTERPRISE':
      return AllowedCustomerTypes.ENTERPRISE;
    case 'TEAM_TRIAL':
      return AllowedCustomerTypes.TEAM_TRIAL;
    default:
      return null;
  }
}

export default () => {
  const query = gql`
    query GetUser($requestContext: RequestContext!) {
      user(requestContext: $requestContext) {
        ... on UserOutput {
          user {
            profile {
              email
            }
            workspaces {
              teams {
                uid
                adminUid
                members {
                  email
                  uid
                }
              }
              billingMetadata {
                customerType
              }
            }
          }
        }
      }
    }
  `;

  const variables = {
    requestContext: getRequestContext(),
  };

  const parseData = (data: any): TeamInfoForUpgradeView[] => {
    if (!data?.user?.user?.workspaces?.[0]?.teams) {
      return [];
    }

    const workspace = data.user.user.workspaces[0];
    const { billingMetadata } = workspace;

    return data.user.user.workspaces[0].teams.map((team: any) => {
      const adminEmail = team.members.find(
        (member: any) => member.uid === team.adminUid
      )?.email;
      const customerType =
        mapCustomerTypeToEnum(billingMetadata.customerType) ??
        AllowedCustomerTypes.FREE;

      const teamInfo: TeamInfoForUpgradeView = {
        uid: team.uid,
        adminUid: team.adminUid,
        customerType,
        members: team.members,
        adminEmail,
      };
      return teamInfo;
    });
  };

  return { query, variables, parseData };
};
