import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EmailAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
} from 'firebase/auth';
import './auth-error-view.css';
import { AuthErrorType } from './auth-helper';

interface AuthErrorViewProps {
  error: AuthErrorType | undefined;
  previousProviderId: string | undefined;
  logout: () => Promise<void>;
  isAnonymous: boolean;
}

const getFriendlyProviderName = (providerId: string | undefined) => {
  switch (providerId) {
    case GoogleAuthProvider.PROVIDER_ID:
      return 'Google';
    case GithubAuthProvider.PROVIDER_ID:
      return 'GitHub';
    case EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD:
      return 'your email address';
    // TODO: should be one for SSO
    default:
      return null;
  }
};

const AuthErrorView = ({
  isAnonymous,
  error,
  previousProviderId,
  logout,
}: AuthErrorViewProps) => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    if (isAnonymous) {
      logout().then(() => {
        navigate('/login', { replace: true });
      });
    } else {
      logout();
    }
  };

  if (!error) {
    return null;
  }

  let errorMessage = <></>;
  const logoutMessage = (
    <p>
      If you are trying to sign into an existing account,
      <button
        type="button"
        className="auth-error-button"
        onClick={logoutAndRedirect}
      >
        click here
      </button>
      .
    </p>
  );

  switch (error) {
    case AuthErrorType.AuthedWithDifferentProvider:
      errorMessage = (
        <>
          It looks like you previously signed in using{' '}
          {getFriendlyProviderName(previousProviderId)}. Please try again with
          that sign-in method.
        </>
      );
      break;
    case AuthErrorType.NoSsoConnection:
      errorMessage = <>There is no SSO provider associated with this domain.</>;
      break;
    case AuthErrorType.MustUseSso:
      errorMessage = (
        <>
          Your account is configured to require sign-in via SSO. Please use that
          method.
        </>
      );
      break;
    case AuthErrorType.EmailAlreadyExists:
      errorMessage = (
        <>
          <p>Another account already exists with that email.</p>
          {logoutMessage}
        </>
      );
      break;
    case AuthErrorType.CredentialAlreadyInUse:
      errorMessage = (
        <>
          <p>Another account already exists with that credential.</p>
          {logoutMessage}
        </>
      );
      break;
    default:
      errorMessage = (
        <>Oops! We were unable to sign you in. Please try again.</>
      );
      break;
  }

  return <div className="auth-error">{errorMessage}</div>;
};

export default AuthErrorView;
