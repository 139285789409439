import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export interface JoinTeamWithInviteEmailVariables {
  emailAuthCode: string | undefined;
}

export default (variables: JoinTeamWithInviteEmailVariables) => {
  const query = gql`
    mutation JoinTeamWithInviteEmail(
      $input: JoinTeamWithInviteEmailInput!
      $requestContext: RequestContext!
    ) {
      joinTeamWithInviteEmail(input: $input, requestContext: $requestContext) {
        ... on JoinTeamWithInviteEmailOutput {
          success
        }
      }
    }
  `;

  const vars = {
    input: {
      emailAuthCode: variables.emailAuthCode,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.joinTeamWithInviteEmail?.success;
  };

  return { query, variables: vars, parseData };
};
