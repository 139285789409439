import { gql } from '@apollo/client';
import {
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from '../../upgrade-view/UpgradeEnums';
import getRequestContext from '../get-request-context';

interface SendUpgradeRequestVariables {
  teamUid: string;
  interval: StripeSubscriptionInterval;
  plan: StripeSubscriptionPlan;
  reason: string;
}

export default () => {
  const query = gql`
    mutation SendUpgradeRequest(
      $input: SendUpgradeRequestInput!
      $requestContext: RequestContext!
    ) {
      sendUpgradeRequest(requestContext: $requestContext, input: $input) {
        ... on SendUpgradeRequestOutput {
          success
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  return {
    query,
    createVariables: (variables: SendUpgradeRequestVariables) => ({
      input: variables,
      requestContext: getRequestContext(),
    }),
    parseData: (data: any): boolean | undefined =>
      data?.sendUpgradeRequest?.success,
  };
};
