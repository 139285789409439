import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export interface UserForUserDataView {
  email: string;
  teamName: string;
  adminUid: string;
  members: { email: string }[];
}

export default () => {
  const query = gql`
    query GetUser($requestContext: RequestContext!) {
      user(requestContext: $requestContext) {
        ... on UserOutput {
          user {
            profile {
              email
            }
            workspaces {
              teams {
                name
                adminUid
                members {
                  email
                }
              }
            }
          }
        }
      }
    }
  `;

  const variables = {
    requestContext: getRequestContext(),
  };

  const parseData = (data: any): UserForUserDataView => {
    return {
      email: data?.user?.user?.profile?.email,
      teamName: data?.user?.user?.workspaces[0]?.teams[0]?.name,
      adminUid: data?.user?.user?.workspaces[0]?.teams[0]?.adminUid,
      members: data?.user?.user?.workspaces[0]?.teams[0]?.members,
    };
  };

  return { query, variables, parseData };
};
