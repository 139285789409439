import { gql } from '@apollo/client';
import { StripeSubscriptionInterval } from '../../upgrade-view/UpgradeEnums';
import getRequestContext from '../get-request-context';

interface TeamPlanUpdateConfirmationPageVariables {
  teamUid: string | undefined;
  interval: StripeSubscriptionInterval;
}

export default () => {
  const query = gql`
    mutation TeamPlanUpdateConfirmationPage(
      $input: TeamPlanUpdateConfirmationPageInput!
      $requestContext: RequestContext!
    ) {
      teamPlanUpdateConfirmationPage(
        requestContext: $requestContext
        input: $input
      ) {
        ... on TeamPlanUpdateConfirmationPageOutput {
          url
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  return {
    query,
    createVariables: (variables: TeamPlanUpdateConfirmationPageVariables) => ({
      input: variables,
      requestContext: getRequestContext(),
    }),
    parseData: (data: any): string | undefined => {
      return data?.teamPlanUpdateConfirmationPage?.url;
    },
  };
};
