import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface CheckoutSessionDetailsVariables {
  sessionId: string | null;
}

export default (variables: CheckoutSessionDetailsVariables) => {
  const query = gql`
    query checkoutSessionDetails(
      $input: CheckoutSessionDetailsInput!
      $requestContext: RequestContext!
    ) {
      checkoutSessionDetails(input: $input, requestContext: $requestContext) {
        ... on CheckoutSessionDetailsOutput {
          plan
        }
      }
    }
  `;

  const vars = {
    input: variables,
    requestContext: getRequestContext(),
  };

  return {
    query,
    variables: vars,
  };
};
