import React, { useEffect, useState } from 'react';
import * as querystring from 'querystring';
import { Navigate } from 'react-router-dom';
import { User } from '../AuthenticatedView';
import '../login.css';
import './logged-in-view.css';
import useSearchQuery from '../utils/useSearchQuery';
import { DiscoverableTeam } from '../team-discovery/TeamDiscoveryBox';
import TeamDiscoveryContainer from '../team-discovery/TeamDiscoveryContainer';
import WarpError from '../WarpError';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonTreatment,
  ModalContainerButtonAccent,
} from '../modal-container/ModalContainerButton';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import UserNameAndPhoto from './UserNameAndPhoto';
import NotYouAndFeedback from './NotYouAndFeedback';
import trackEvent from '../utils/trackEvent';

const SHOW_NOT_WORKING_CONTAINER_TIMEOUT_MS = 1000;
const AUTO_FORWARD_URI_TIMEOUT_MS = 1000;

// `AuthResult` is passed via query parameters in the redirect url to Warp App.
interface AuthResult {
  refresh_token: string;
  is_onboarded: boolean;
  user_uid: string;
  deleted_anonymous_user: boolean;
}

interface LoggedInProps {
  user: User;
  deletedAnonymousUser: boolean;
  logout: () => void;
}

function getScheme(scheme: string): string {
  const defaultScheme = process.env.REACT_APP_DEFAULT_SCHEME!.trim();
  const supportedSchemes = process.env
    .REACT_APP_SUPPORTED_SCHEMES!.trim()
    .split(',');
  return (supportedSchemes.includes(scheme) ? scheme : defaultScheme).trim();
}

export function computeAppLaunchUrl(
  queryParams: URLSearchParams,
  authResult: AuthResult
) {
  const scheme = getScheme(queryParams.get('scheme') || '');
  const queryString = querystring.stringify({ ...authResult });
  return `${scheme}://auth/desktop_redirect?${queryString}`;
}

// Computes the AuthResult that is sent back to the Warp Desktop app via an intent to complete sign in.
export function computeAuthResult(user: User, deletedAnonymousUser: boolean) {
  const authResult: AuthResult = {
    refresh_token: user.refreshToken,
    is_onboarded: user.isOnboarded,
    user_uid: user.firebaseUID,
    deleted_anonymous_user: deletedAnonymousUser,
  };
  return authResult;
}

function LoggedInContainer({
  user,
  deletedAnonymousUser,
  logout,
}: LoggedInProps) {
  const [showNotWorkingContainer, setShowNotWorkingContainer] = useState(false);
  const query = useSearchQuery();
  const [autoForwardURI, setAutoForwardURI] = useState<string>('');

  function forwardToWarpApp(
    queryParams: URLSearchParams,
    authResult: AuthResult
  ) {
    setAutoForwardURI(computeAppLaunchUrl(queryParams, authResult));
  }

  useEffect(() => {
    const time = setTimeout(() => {
      if (autoForwardURI !== '') {
        window.location.replace(autoForwardURI);
      }
    }, AUTO_FORWARD_URI_TIMEOUT_MS);

    return () => clearTimeout(time);
  }, [autoForwardURI]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNotWorkingContainer(true);
    }, SHOW_NOT_WORKING_CONTAINER_TIMEOUT_MS);

    // Ensure the timeout is cleared when the component is unmounted.
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (user) {
      const authResult = computeAuthResult(user, deletedAnonymousUser);
      forwardToWarpApp(query, authResult);
    }
  }, [deletedAnonymousUser, query, user]);

  if (showNotWorkingContainer) {
    document.querySelector('.not-working-container')?.classList.add('visible');
  }

  if (!user) {
    return <Navigate to="/login/remote" replace />;
  }

  const authResult = computeAuthResult(user, deletedAnonymousUser);
  const copyAuthUrl = () => {
    const url = computeAppLaunchUrl(query, authResult);
    window.navigator.clipboard.writeText(url);
  };

  return (
    <ModalContainer iconType={ModalContainerIconType.Check}>
      <ModalContainerHeader>
        <UserNameAndPhoto user={user} />
      </ModalContainerHeader>
      <ModalContainerBody>
        <div>
          <ModalContainerButton
            href={computeAppLaunchUrl(query, authResult)}
            content={<>Take me to Warp</>}
            treatment={ModalContainerButtonTreatment.FullWidth}
            accent={ModalContainerButtonAccent.Primary}
            onClickFunction={() => {
              trackEvent(user, 'Clicked take me to Warp');
            }}
          />
          <div className="not-working-container">
            Button not working?{' '}
            <ModalContainerButton
              content={<>Click here</>}
              treatment={ModalContainerButtonTreatment.Inline}
              onClickFunction={copyAuthUrl}
            />{' '}
            to copy your auth token, then open Warp and paste the token directly
            into the app.
          </div>
        </div>
        <NotYouAndFeedback user={user} logout={logout} />
      </ModalContainerBody>
    </ModalContainer>
  );
}

const LoggedInView = ({
  user,
  deletedAnonymousUser,
  logout,
}: LoggedInProps) => {
  const [showTeamDiscoveryView, setShowTeamDiscoveryView] = useState(false);
  const [discoverableTeams, setDiscoverableTeams] = useState<
    DiscoverableTeam[]
  >();

  // Retrieve discoverable teams for user
  // Show team discovery page if 1) feature flag is enabled, 2) if there are discoverable teams returned
  useEffect(() => {
    setDiscoverableTeams(user?.joinableTeams);
    if (discoverableTeams && discoverableTeams.length > 0) {
      setShowTeamDiscoveryView(true);
      trackEvent(user, 'Visited team discovery page');
    }
  }, [discoverableTeams, user]);

  const goToNextPage = () => {
    setShowTeamDiscoveryView(false);
  };

  if (user && typeof discoverableTeams === 'undefined') {
    return <WarpError error="Loading..." />;
  }
  return (
    <>
      {showTeamDiscoveryView ? (
        <TeamDiscoveryContainer
          user={user}
          goToNextPage={goToNextPage}
          teams={discoverableTeams!}
        />
      ) : (
        <LoggedInContainer
          user={user}
          deletedAnonymousUser={deletedAnonymousUser}
          logout={logout}
        />
      )}
    </>
  );
};

export default LoggedInView;
