import { gql } from '@apollo/client';
import { Inviter } from '../../join-team/InviterHeading';
import getRequestContext from '../get-request-context';

export interface TeamInfoFromEmailInviteCodeData {
  name: string;
  uid: string;
  inviter: Inviter;
  inviteeEmail: string;
  expired: boolean;
  teamAcceptingInvites: boolean;
}

export interface TeamInfoFromEmailInviteCodeVariables {
  emailAuthCode: string | undefined;
}

export default (variables: TeamInfoFromEmailInviteCodeVariables) => {
  const query = gql`
    query GetTeamInfoFromEmailInviteCode(
      $input: TeamInfoFromInviteEmailCodeInput!
      $requestContext: RequestContext!
    ) {
      teamInfoFromEmailInviteCode(
        input: $input
        requestContext: $requestContext
      ) {
        ... on TeamInfoFromInviteEmailCodeOutput {
          emailInvite {
            email
            expired
          }
          publicTeamInfo {
            name
            uid
            inviterEmail
            inviterDisplayName
            inviterPhotoUrl
            teamAcceptingInvites
          }
        }
      }
    }
  `;

  const vars = {
    input: {
      code: variables.emailAuthCode,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any): TeamInfoFromEmailInviteCodeData => {
    return {
      name: data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.name,
      uid: data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.uid,
      inviter: {
        email: data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.inviterEmail,
        name:
          data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.inviterDisplayName,
        photoUrl:
          data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.inviterPhotoUrl,
      },
      inviteeEmail: data?.teamInfoFromEmailInviteCode?.emailInvite?.email,
      expired: data?.teamInfoFromEmailInviteCode?.emailInvite?.expired,
      teamAcceptingInvites:
        data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.teamAcceptingInvites,
    };
  };

  return { query, variables: vars, parseData };
};
