import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { getAuth } from 'firebase/auth';
import { User } from './AuthenticatedView';
import FullPageModal from './FullPageModal';
import WasmView from './WasmView';
import LoadingScreen from './loading-screen/LoadingScreen';
import firebase from './utils/firebase';

interface SessionShareViewProps {
  user: User;
}

function getAppLaunchURL(sessionID: string, pwd: string | null): string {
  return `${
    process.env.REACT_APP_DEFAULT_SCHEME
  }://shared_session/${sessionID}${pwd ? `?pwd=${pwd}` : ''}`;
}

function getSessionExistenceCheckURL(sessionID: string): string {
  return `${process.env.REACT_APP_SESSION_SHARING_ROOT_URL}/sessions/${sessionID}`;
}

const SessionShareView = ({ user }: SessionShareViewProps) => {
  // TODO figure out the correct analytics
  if (user?.isTelemetryEnabled) {
    window.rudderanalytics.track('Visited join session view');
  }
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const params = useParams<{ id: string }>();

  const sessionID = params.id;
  // TODO: remove sessionPwd, no longer needed after ACL integrations.
  const sessionPwd = queryParams.get('pwd');

  const [isLoading, setIsLoading] = useState(true);
  const [sessionExists, setSessionExists] = useState(false);
  const [existenceCheckError, setExistenceCheckError] = useState('');

  useEffect(() => {
    if (sessionID != null) {
      getAuth(firebase)
        .currentUser?.getIdToken()
        .then((token) => {
          return fetch(getSessionExistenceCheckURL(sessionID), {
            method: 'HEAD',
            headers: {
              Authorization: token ? `Bearer ${token}` : '',
            },
          });
        })
        .then((response) => {
          setSessionExists(response.status === 204);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setExistenceCheckError(
            `Error validating session: ${error?.message.trim()}`
          );
        });
    } else {
      setIsLoading(false);
    }
  }, [user, sessionID]);

  if (isLoading || !user) {
    return <LoadingScreen />;
  }

  if (existenceCheckError !== '') {
    return <FullPageModal error={existenceCheckError} />;
  }

  if (!sessionID || !sessionExists) {
    return <FullPageModal error="Session could not be found." />;
  }

  // Otherwise just return the wasm component
  return (
    <WasmView
      splashPageMessage="Open Warp to join this session."
      appLaunchUrl={getAppLaunchURL(sessionID, sessionPwd)}
    />
  );
};

export default SessionShareView;
