import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

export interface JoinTeamWithInviteCodeVariables {
  code: string | undefined;
}

export default (variables: JoinTeamWithInviteCodeVariables) => {
  const query = gql`
    mutation JoinTeamWithInviteCode(
      $input: JoinTeamWithInviteCodeInput!
      $requestContext: RequestContext!
    ) {
      joinTeamWithInviteCode(input: $input, requestContext: $requestContext) {
        ... on JoinTeamWithInviteCodeOutput {
          success
        }
      }
    }
  `;

  const vars = {
    input: {
      code: variables.code,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.joinTeamWithInviteCode?.success;
  };

  return { query, variables: vars, parseData };
};
