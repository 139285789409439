import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface SendVerificationEmailVariables {
  inviteCode: string;
  email: string;
}

export default (variables: SendVerificationEmailVariables) => {
  const query = gql`
    mutation SendVerificationEmail(
      $input: SendVerificationEmailWithCodeInput!
      $requestContext: RequestContext!
    ) {
      sendVerificationEmailWithCode(
        input: $input
        requestContext: $requestContext
      ) {
        ... on SendVerificationEmailWithCodeOutput {
          success
        }
      }
    }
  `;

  const vars = {
    input: {
      teamInviteCode: variables.inviteCode,
      emailToVerifyWith: variables.email,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.sendVerificationEmailWithCode?.success;
  };

  return { query, variables: vars, parseData };
};
