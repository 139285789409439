import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface GetBlockVariables {
  uid: string | undefined;
}

export default (variables: GetBlockVariables) => {
  const query = gql`
    query GetBlock($input: GetBlockInput!, $requestContext: RequestContext!) {
      block(input: $input, requestContext: $requestContext) {
        ... on GetBlockOutput {
          block {
            title
            stylizedPrompt
            stylizedCommand
            stylizedPromptAndCommand
            stylizedOutput
            command
            output
            showPrompt
            embedDisplaySetting
          }
        }
      }
    }
  `;

  const vars = {
    input: {
      uid: variables.uid,
    },
    requestContext: getRequestContext(),
  };

  return {
    query,
    variables: vars,
    parseData: (data: any) => data.block.block,
  };
};
