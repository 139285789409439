import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Warp404 from './404';
import { User } from './AuthenticatedView';
import SuccessfulJoin from './join-team/SuccessfulJoin';
import AlreadyInCurrentTeam from './join-team/AlreadyInCurrentTeam';
import AlreadyInOtherTeam from './join-team/AlreadyInOtherTeam';
import DomainNotApproved from './join-team/DomainNotApproved';
import JoinTeam from './join-team/JoinTeam';
import ContactAdminToUpgrade from './join-team/ContactAdminToUpgrade';
import SignInAndJoinTeamView from './SignInAndJoinTeamView';
import WarpError from './WarpError';
import './login.css';
import { checkAppInstallation } from './utils/app_detection';
import JoinTeamWithInviteCode from './graphql/mutations/JoinTeamWithInviteCode';
import GetUserWorkspaces, {
  Workspace,
} from './graphql/queries/GetUserWorkspaces';
import GetTeamInfoFromInviteCode, {
  TeamInfoFromInviteCodeData,
} from './graphql/queries/GetTeamInfoFromInviteCode';
import { FEATURE_FLAGS } from './utils/env';

export interface JoinTeamProps {
  user: User;
  logout: () => Promise<void>;
}

const JoinTeamView = ({ user, logout }: JoinTeamProps) => {
  const { inviteCode } = useParams<{
    inviteCode: string;
  }>();

  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const [hasApprovedDomain, setHasApprovedDomain] = useState(false);
  const [appDetectionLoading, setAppDetectionLoading] = useState<boolean>(true);
  const [appDetected, setAppDetected] = useState<boolean>(true);

  const [
    teamInfoFromInviteCodeData,
    setTeamInfoFromInviteCodeData,
  ] = useState<TeamInfoFromInviteCodeData>();
  const [workspaces, setWorkspaces] = useState<Workspace[]>();

  function checkIfApprovedDomain(approvedDomains: string[]) {
    const domain = user?.email?.slice(user?.email?.lastIndexOf('@') + 1);
    if (
      approvedDomains?.length === 0 ||
      (domain !== undefined && approvedDomains?.includes(domain))
    ) {
      setHasApprovedDomain(true);
    }
  }

  const {
    query: joinTeamQuery,
    variables: joinTeamVariables,
    parseData: joinTeamParseData,
  } = JoinTeamWithInviteCode({ code: inviteCode });
  // Mutation to join a team. Doesn't execute until `joinTeamMutation` is invoked.
  const [joinTeamMutation] = useMutation(joinTeamQuery, {
    variables: joinTeamVariables,
    onCompleted(data) {
      setJoinedSuccessfully(joinTeamParseData(data));
    },
    onError(err) {
      // Pop an error if failed to join team.
      err.graphQLErrors.forEach((error) => {
        // eslint-disable-next-line no-alert
        alert(`Failed to join team. ${error.message}`);
      });
    },
  });

  // Check if the invite code is valid.
  // Skip the query if the user is not even logged in.
  const {
    query: getTeamInfoFromInviteCodeQuery,
    variables: getTeamInfoFromInviteCodeVariables,
    parseData: getTeamInfoFromInviteCodeParseData,
  } = GetTeamInfoFromInviteCode({ code: inviteCode });
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(getTeamInfoFromInviteCodeQuery, {
    skip: !user,
    variables: getTeamInfoFromInviteCodeVariables,
    onCompleted(data) {
      const parsedData = getTeamInfoFromInviteCodeParseData(data);
      setTeamInfoFromInviteCodeData(parsedData);
      checkIfApprovedDomain(parsedData.approvedDomains);
    },
  });

  // Check if the user is already part of any workspaces.
  // For now, we limit the user to a single workspace.
  // Skip the query if the user is not even logged in.
  const {
    query: getUserWorkspacesQuery,
    variables: getUserWorkspacesVariables,
    parseData: getUserWorkspacesParseData,
  } = GetUserWorkspaces();
  const { loading: userTeamsQueryLoading } = useQuery(getUserWorkspacesQuery, {
    skip: !user,
    variables: getUserWorkspacesVariables,
    onCompleted(data) {
      setWorkspaces(getUserWorkspacesParseData(data));
    },
  });

  // Check if application is detected
  useEffect(() => {
    checkAppInstallation().then((detected) => {
      setAppDetected(detected);
      setAppDetectionLoading(false);
    });
  }, []);

  // Show sign in page if user not logged in
  if (!user) {
    return <SignInAndJoinTeamView user={user} logout={logout} />;
  }

  if (inviteCodeQueryLoading || userTeamsQueryLoading) {
    return <WarpError error="Loading..." />;
  }

  if (inviteCodeInvalid || !teamInfoFromInviteCodeData?.isInviteLinkEnabled) {
    return <Warp404 />;
  }

  if (appDetectionLoading) {
    return <WarpError error="Loading..." />;
  }

  // If the user has managed to successfully join...
  if (joinedSuccessfully) {
    return (
      <SuccessfulJoin
        appDetected={appDetected}
        teamNameFromInviteCode={teamInfoFromInviteCodeData.name}
      />
    );
  }

  // Case 1: user is already in the team
  if (teamInfoFromInviteCodeData?.uid) {
    if (
      workspaces?.some((workspace) =>
        workspace.teams.some(
          (team) => team.uid === teamInfoFromInviteCodeData.uid
        )
      )
    ) {
      return (
        <AlreadyInCurrentTeam
          appDetected={appDetected}
          teamNameFromInviteCode={teamInfoFromInviteCodeData.name}
        />
      );
    }
  }

  // Case 2: If the team doesn't have space for more members
  if (!teamInfoFromInviteCodeData?.teamAcceptingInvites) {
    return (
      <ContactAdminToUpgrade
        inviterFromInviteCode={teamInfoFromInviteCodeData?.inviter}
        teamNameFromInviteCode={teamInfoFromInviteCodeData.name}
      />
    );
  }

  // Case 3: user is already in a workspace AND multi-workspace is not enabled
  const currWorkspaceName = workspaces?.[0]?.name;
  if (currWorkspaceName && !FEATURE_FLAGS.REACT_APP_ENABLE_MULTI_WORKSPACE) {
    return (
      <AlreadyInOtherTeam
        appDetected={appDetected}
        existingTeamName={currWorkspaceName}
        inviterFromInviteCode={teamInfoFromInviteCodeData?.inviter}
        teamNameFromInviteCode={teamInfoFromInviteCodeData?.name}
      />
    );
  }

  // Case 4: email domain is not approved
  if (!hasApprovedDomain) {
    return (
      <DomainNotApproved
        inviteCode={inviteCode || ''}
        setHasApprovedDomain={setHasApprovedDomain}
        setJoinedSuccessfully={setJoinedSuccessfully}
        teamApprovedDomains={teamInfoFromInviteCodeData?.approvedDomains}
      />
    );
  }

  // else... allow them to join the team and open Warp
  return (
    <JoinTeam
      inviterFromInviteCode={teamInfoFromInviteCodeData?.inviter}
      onClickFunction={() => joinTeamMutation()}
      teamNameFromInviteCode={teamInfoFromInviteCodeData?.name}
      userEmail={user.email}
    />
  );
};

export { JoinTeamView };
