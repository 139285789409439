import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';

interface GetOrganizationVariables {
  email: string;
}

export default (variables: GetOrganizationVariables) => {
  const query = gql`
    query organization(
      $input: OrganizationInput!
      $requestContext: RequestContext!
    ) {
      organization(input: $input, requestContext: $requestContext) {
        ... on OrganizationOutput {
          organizationId
          ssoEnabled
        }
      }
    }
  `;

  const vars = {
    input: {
      email: variables.email,
    },
    requestContext: getRequestContext(),
  };

  const parseData = (data: any) => {
    return data?.organization;
  };

  return { query, variables: vars, parseData };
};
