import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';
import {
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from '../../upgrade-view/UpgradeEnums';

interface StripeCheckoutSessionVariables {
  teamUid: string | undefined;
  interval: StripeSubscriptionInterval;
  plan: StripeSubscriptionPlan;
}

export default () => {
  const query = gql`
    mutation stripeCheckoutSessionWithIntervalAndPlan(
      $input: StripeCheckoutSessionInput!
      $requestContext: RequestContext!
    ) {
      stripeCheckoutSession(input: $input, requestContext: $requestContext) {
        ... on StripeCheckoutSessionOutput {
          url
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  return {
    query,
    createVariables: (variables: StripeCheckoutSessionVariables) => ({
      input: variables,
      requestContext: getRequestContext(),
    }),
    parseData: (data: any) => data.stripeCheckoutSession?.url,
  };
};
